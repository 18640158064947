:root{
  --color_togglemenu_close: white;
  --color-togglemenu_open: black;
  --bgcolor_menu: #8e7d7dc2;
  --time_transiction: 330ms;
  --font_color: #FFF;
  --bg_color: #217aa4;
 /*--bgImg: url("/public/img/bg4.jpg");*/

}

*, *:before, *:after {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
  border: 0;
  font-size: 100%;
  line-height: 100%;
  -webkit-text-size-adjust: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}          



.sitehead{
  xwidth: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: transparent;
  z-index: 800;
  /*transition: background-color ease .6s;*/
  transition: width ease .6s,opacity ease .6s,background-color ease .6s,-webkit-filter ease .6s;
  transition: width ease .6s,opacity ease .6s,filter ease .6s,background-color ease .6s;
  transition: width ease .6s,opacity ease .6s,filter ease .6s,background-color ease .6s,-webkit-filter ease .6s;                
}

.sitehead__inner { 
  z-index: 1;
  xpadding: 0 20px; 
  xheight: 60px;                
  display: flex;
  align-items:flex-start;
  margin: 3vh 2vw;
  
}            

@media (min-width: 670px) {	/* sotto i 670px */
  .sitehead__inner {  
      xpadding: 0 50px; 
      xheight: 80px; 
  }   
}
a{ text-decoration: none; }
/*LOADING ------------------------------------------------------------*/
.alcentro{
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  min-height: 100vh;
  background-color: #dee8de;
  flex-direction: column;
  justify-content: center;
  color: #5b7356;
}
.alcentro span:nth-last-child(1){
  font-weight: bold;
  line-height: 35px;
}

/*MENU ------------------------------------------------------------------------------------------------------------------------*/
.sitemenu{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  background: var(--bgcolor_menu);
}
.menu-open .sitemenu {
  opacity:1;
  background-color: var(--bgcolor_menu);
}

body:not(.menu-open) .sitemenu {
  opacity: 0;
  visibility: hidden;
  transform: translateY(-100%);
  transition: transform cubic-bezier(0.39, 0.83, 0.42, 0.82) .8s .2s,opacity ease 0s 1s,visibility ease 0s 1s;
}
body.menu-open .sitemenu {
  opacity: 1;
  visibility: visible;
  transform: translateY(0%);
  transition: transform cubic-bezier(0.54, 0.15, 0.32, 0.99) 1s;
}


/*TASTO MENU ------------------------------------------------------------------------------------------------------------------------*/
.togglemenu{
  width: 40px;
  height: 30px;
  display: block;
  position: relative;
  margin-left: auto;
  Z-INDEX: 1;
}

.togglemenu span{
  position: absolute;
  height:6px;
  background-color: var(--color_togglemenu_close);
  transition: width ease .6s, opacity ease .7s, scale ease .7s ;
}

.togglemenu span:nth-child(n+4){
  width: 5px;
  top: 50%;
  left: 50%;
  opacity: 0;
}            
.togglemenu span:nth-child(1) {left: 0;top:0;}
.togglemenu span:nth-child(2) {right:0;top:13px;}
.togglemenu span:nth-child(3) {left: 0;top:26px;}
.togglemenu span:nth-child(4) {transform: translateX(-50%) rotate(45deg);}
.togglemenu span:nth-child(5) {transform: translateX(-50%) rotate(-45deg);}

.togglemenu:hover span:nth-child(-n+3) { 
  width: 75%; transition: width ease .6s;
}
.togglemenu span:nth-child(-n+3) {
  width:100%;                
}            
.menu-open .togglemenu span:nth-child(-n+3) {
  width:0;
  transition-delay: .0s, .0s;
}
.menu-open .togglemenu span:nth-child(n+4){
  width: 100%; 
  opacity: 1;
  transition-delay: .5s, .5s; 
}
.togglemenu:hover span:nth-child(4) {transform: translateX(-50%) rotate(45deg) scale(1.1);}
.togglemenu:hover span:nth-child(5) {transform: translateX(-50%) rotate(-45deg) scale(1.1);}

/*TASTO MENU LANG ------------------------------------------------------------------------------------------------------------------------*/
.toggleflagmenu{
  display: block;
  position: relative; 
  margin-right: auto;
  Z-INDEX: 1;
  list-style:none;
}
.toggleflagmenu img {
  BORDER-RADIUS: 50%;
  border: 2px white solid;
  width: 48px;
  height: 48px; 
  vertical-align: middle;               
  margin-bottom: 5px;
}
.toggleflagmenu a {
  text-decoration: none;
}
.toggleflagmenu a span {
  color:var(--font_color);
  font-weight: bold;
  display: none;
  font-family: 'Roboto', sans-serif;
  box-shadow: 1px 1px 7px #000000f0;
}
.toggleflagmenu a:hover span { 
  display:inline;
  animation: fadeInLeftBig; animation-duration: .8s; animation-fill-mode:both;
}
.toggleflagmenu a:hover img { 
 box-shadow: 0 0 7px 3px #fff;
}

.menu-open .toggleflagmenu{
  Z-INDEX: -1;
}
.toggleflagmenu li:not(:first-child) {                              
  animation: fadeOutLeftBig;animation-duration: .8s; animation-fill-mode: both; Xdisplay:none;
  vertical-align: middle;
}
.toggleflagmenu:hover li:not(:first-child) {                              
  animation: fadeInLeftBig; animation-duration: .8s; animation-fill-mode:both;
} 

.toggleflagmenu:hover li:nth-child(3) { animation-delay: .1s;}
.toggleflagmenu:hover li:nth-child(4) { animation-delay: .3s;}
.toggleflagmenu:hover li:nth-child(5) { animation-delay: .5s;}
.toggleflagmenu:hover li:nth-child(6) { animation-delay: .7s;}
.toggleflagmenu:hover li:nth-child(7) { animation-delay: .9s;}
.toggleflagmenu:hover li:nth-child(8) { animation-delay: 1.1s;}

/* MENU APERTO -----------------------------------------------------------------------------------------*/
.sitemenu a{
  text-decoration: none;
  color:var(--font_color);
  text-shadow: 1px 1px 7px #000000f0;
}
.sitemenu a:hover{
  text-shadow: 1px 1px 7px #fffffff0;
}            
.sitemenu > div {
  display: grid;
  grid-template-columns: auto auto;
  column-gap: 4em;
  row-gap: 2em;
}            
.sitemenu a i{
  width: 90px;
  text-align: center;
  vertical-align: middle;
  font-size: 55px;
}
.sitemenu div.sitemenu_item { 
  opacity: 0;
  font-family: 'Luckiest Guy', cursive;
  font-size: 50px;
  letter-spacing: 1px;            
  animation: fadeOutUp;animation-duration: 1.2s; animation-fill-mode: forwards;
}

.menu-open .sitemenu div.sitemenu_item {                              
  animation: fadeInDown;animation-duration: 1.2s; animation-fill-mode: forwards;
} 
.menu-open .sitemenu div.sitemenu_item:nth-child(1),.menu-open .sitemenu div.sitemenu_item:nth-child(2) { animation-delay: .6s;}
.menu-open .sitemenu div.sitemenu_item:nth-child(3),.menu-open .sitemenu div.sitemenu_item:nth-child(4) { animation-delay: .9s;}
.menu-open .sitemenu div.sitemenu_item:nth-child(5),.menu-open .sitemenu div.sitemenu_item:nth-child(6) { animation-delay: 1.2s;}
.menu-open .sitemenu div.sitemenu_item:nth-child(7) { animation-delay: 1.8s;}      
.sitemenu div.sitemenu_item:nth-last-child(1){
  grid-column-start: 1;
  grid-column-end: 3;
  justify-self: center;
}

.w3w{
    display: flex;
}
.w3w img{
  width: 45px;
  margin: 0 30px;
}
.w3w span::after {
  content: ".";
}
.w3w span:nth-last-child(1)::after {
  content: "";
}
.w3w>div {
  display: flex;
  flex-direction: row;
}

@media (max-width: 900px) {	/* sotto i 900px */
  .sitemenu > div {  
      grid-template-columns: auto;
      column-gap: 0;
      row-gap: 0.8em;
  }   
  .sitemenu div.sitemenu_item { justify-self: center; }
  .menu-open .sitemenu div.sitemenu_item:nth-child(1) { animation-delay: .6s;}
  .menu-open .sitemenu div.sitemenu_item:nth-child(2) { animation-delay: .9s;}
  .menu-open .sitemenu div.sitemenu_item:nth-child(3) { animation-delay: 1.2s;}
  .menu-open .sitemenu div.sitemenu_item:nth-child(4) { animation-delay: 1.5s;}
  .menu-open .sitemenu div.sitemenu_item:nth-child(5) { animation-delay: 1.8s;}
  .menu-open .sitemenu div.sitemenu_item:nth-child(6) { animation-delay: 2.1s;}      
  .menu-open .sitemenu div.sitemenu_item:nth-child(7) { animation-delay: 2.4s;}      
  
  .sitemenu div.sitemenu_item:nth-last-child(1){
    margin-top: 8px;
    grid-column-start: unset;
    grid-column-end: unset;
  }

  .w3w>div {
    flex-direction: column;
  }
  .w3w img{
    width: 35px;
    margin: 0 14px;
  }
  .w3w span{
    font-size: 23px;
    margin-top: -6px;    
  }
  .w3w span::after {
    content: "";
  }

  .sitemenu a i{
    width: 50px;
    font-size: 35px;
  }
  .sitemenu div.sitemenu_item { 
      font-size: 30px;
  }  
  
} 


/*--  develop by ---------------------------------------------------------*/
.devby{
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: flex-start;
}
.devby div{
  color: #101010;
  margin-left: auto;
  padding: 2px 15px;
  background-color: #ffffffba;
  border-radius: 5px 0 0 0;
}
.devby div a{
  color: #101010;
  font-weight: bold;
}


/* -------------------------------------------------------------------------------------------------------- */
.bgMain{
  background-position: 50%;
    background-size: cover;
    display: flex;
    background-attachment: fixed;
}

/* -- sezione uno -----------------------------------------------------------------------------------------------------*/
#hp01{
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  
  position: relative;
  background-position: center;
  background-size: cover;
  /*
  background-image:var(--bgImg);
  min-height: 100vh;
  */
}  

.sez01{
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  min-height: 100vh;
  position: relative;
  background-position: center;
  background-size: cover;
  /*background-image:var(--bgImg);*/
}  



#logo{
  width: 400px;
  -webkit-animation:frulla 12s linear infinite;
  -moz-animation:frulla 12s linear infinite;
  animation:frulla 12s linear infinite;
}
#xlogo:hover{
  animation: animate__tada; animation-fill-mode: initial;animation-delay: 0;
}
@-moz-keyframes frulla { 
  40% {-webkit-transform:rotate(0deg);} 
  55% {-moz-transform: rotate(-10deg);} 
  70% {-webkit-transform:rotate(0deg);} 
  85% {-moz-transform: rotate(10deg);} 
  100% {-webkit-transform:rotate(0deg);} 

}
@-webkit-keyframes frulla { 
  40% {-webkit-transform:rotate(0deg);} 
  55% {-webkit-transform: rotate(-10deg);} 
  70% {-webkit-transform:rotate(0deg);} 
  85% {-webkit-transform: rotate(10deg);} 
  100% {-webkit-transform:rotate(0deg);} 
}
@keyframes frulla { 
  40% {-webkit-transform: rotate(0deg); transform:rotate(0deg);} 
  55% { -webkit-transform: rotate(-10deg); transform:rotate(-10deg); } 
  70% {-webkit-transform: rotate(0deg); transform:rotate(0deg);} 
  85% { -webkit-transform: rotate(10deg); transform:rotate(10deg); } 
  100% {-webkit-transform: rotate(0deg); transform:rotate(0deg);} 
}



.sez01>div{
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  width: 90vw;
  max-width: 1000px;
}
.sez01 .txt{
  color:var(--font_color);
  font-size: 2.4em;
  font-family: 'Teko', sans-serif;
  text-shadow: 1px 1px 7px #000000f0;
}

.sez01 H2{
  margin-bottom: 3vh;
}

.sez01>div div:nth-child(2){
  flex-grow: 3;
}
@media (max-width: 1100px) {	/* sotto i xxxx */
  #logo{ width: 300px; }
}
@media (max-width: 900px) {	/* sotto i xxxx */
  #logo{ width: 300px; }
  .sez01 .txt{
    font-size: 2em;
  }
}
@media (max-width: 700px) {	/* sotto i xxxx */
  #logo{ margin-top: 20px;}
  .sez01>div{
    flex-flow: column;
  }
  .sez01 .txt{
    text-align: center;
  }  
}
@media (max-width: 450px) {	/* sotto i xxxx */
  #logo{ width: 250px; margin-top: 30px;}
}

.boxeffetto{
  background-color: rgba(255,255,255,0.6);
  border: 25px solid #ffff0069;
  min-width: 80vw !important;
  min-height: 200px;
  border-radius: 25px;
}


/* -- sezione due -----------------------------------------------------------------------------------------------------*/
.sez02{
  display: grid;
  grid-template-columns: 50% 50%;
  min-height: 70vh;
  xalign-items: center;
  justify-content: center;
  position: relative;
  background-color: var(bg_color);
  overflow: hidden;
  padding: 12vh 13vw; 
  background-size: cover;
  background-attachment: fixed;
  border-bottom: 10px solid white;
  border-top: 10px solid white;
}
.sez02 > div{ text-align: center;}
.hplink_item{
  font-family: 'Luckiest Guy', cursive;
  font-size: 45px;
  letter-spacing: 1px;
  line-height: 80px;
  margin: 25px 0;
}
.hplink_item a{
  display: inline-block;
  color: var(--font_color);
  text-decoration: none;
  text-shadow: 1px 1px 7px #000000f0;
}
.hplink_item a i{
  display: block;
  margin: 5px;
  font-size: 1.3em;
}
.hplink_item a:hover{
  animation: flash 700ms linear 2;
  -webkit-animation:flash 700ms linear 2;
  -moz-animation:flash 700ms linear 2;
  animation:flash 700ms linear 2;  
}

@media (max-width: 730px) {	/* sotto i xxxx */
  .sez02{
    padding: 10vh 4vw; 
    background-size: unset;
  }
  .hplink_item{
    font-size: 40px;
  }  
}
@media (max-width: 505px) {	/* sotto i xxxx */
  .sez02{
    padding: 8vh 1vw; 
    grid-template-columns: 100%;
    background-size: cover;
  }
  .hplink_item{
    line-height: 70px;
    margin: 0;
  }
  .hplink_item a i{
    display: inline-block;
    margin: 0;
    margin-right: 0.4em;
    vertical-align: middle;
  }

}

@media (max-width: 400px) {	/* sotto i xxxx */
  .hplink_item{
    font-size: 35px;
  }  
  .hplink_item a i{
    font-size: 1em;
    margin-right: 0.3em;
  }  
}

.code-box-shape {
  position: relative !important;
  border-radius: 4px;
  background: #1890ff;
  width: 50px;
  height: 50px;
  margin: auto;
}

.xcode-box, .xode-box-demo, .xcode-box-shape {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 50px;
  height: 50px;
  border-radius: 4px;
  background: #1890ff;
}
.xcode-box-shape {
  position: relative !important;
}

.xqueue-anim-demo {
  float: left;
  margin: 0 5px 0 0!important;
}
/*-- Footer hp -------------------------------------------------*/
footer {
  xmin-height: 40vh;
  background-color: var(--bg_color);
  border-top: 10px solid white;
  text-align: center;
  padding-bottom: 30px;
}

footer > div{
  max-width: 80vw;
  margin: 15px auto;
  text-align: center;
  color:var(--font_color);
  line-height: 25px;
  font-size: 20px;
}

.cell{
  margin: 10px;
  padding: 10px;
  background-color: var(--font_color);
  display: inline-block;
  BORDER-RADIUS: 8px 15px;
  box-shadow: 2px 3px 9px #000000f0
}
.cell a, .cell a:hover{
  color:var(--bg_color);  
  font-weight: bold;
  line-height: 25px;  
}
.cell a:hover {
  
  animation: heartBeat;
  -webkit-animation:heartBeat;
  -moz-animation:heartBeat;
  animation:heartBeat;   
  
}
.cell i{
  margin: 0 5px;
  font-size: 1.3em;
}